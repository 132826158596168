import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"

const imageBaconWaffles = '/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/wbb_baconwaffles_1362x737_V2.jpg';

const RecipePage = () => (
    <Layout metaTitle="Maple Flavored Bacon Waffles Recipe"
        metaDescription="In our version of waffles, we put maple-flavored bacon straight into the batter, & infuse even more bacon flavor in butter. Try the recipe today!"
        metaKeywords="maple bacon, bacon flavored waffles"
    >
        <meta property="og:image" content={imageBaconWaffles} />
        <link rel="image_src" href={imageBaconWaffles} />
        <div id="recipe-details-wrapper" >
            <div class="image">
                <img src={imageBaconWaffles} class='img-responsive' alt="Maple-Flavored Bacon Waffles" />
            </div>

            <div class="inner-wrapper">
                <div id="recipe-details">
                    <div class="container">
                        <div class="content-wrapper">
                            <h1>Maple-Flavored Bacon Waffles</h1>

                            <div class="recipe-content">
                                <p >We&rsquo;ve made it pretty obvious that we think our thick, hearty bacon can fit in at every meal. But let there be no doubt that breakfast is bacon&rsquo;s true home. In our version of waffles, we put maple-flavored bacon straight into the batter, and infuse even more bacon flavor in decadent homemade butter to ensure bacon gets its chance to shine.&nbsp;&nbsp;</p>
                            </div>
                        </div>

                        <div class="timing row">
                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/prepIcon.png" alt="Prep Time" />
                                </div>
                                <div class="details">
                                    <h4>Prep Time:</h4>
                                    <p>
                                        15&nbsp;minutes                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/cookIcon.png" alt="Cook Time" />
                                </div>
                                <div class="details">
                                    <h4>Cook Time:</h4>
                                    <p>
                                        25&nbsp;minutes                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/serveIcon.png" alt="Serving Size" />
                                </div>
                                <div class="details">
                                    <h4>Serving Size:</h4>
                                    <p>6 </p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div class="ingredients-wrapper recipe-section">
                    <div class="container">


                        <h2>Ingredients</h2>
                        <div class="row ingredients">
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 <span class="uom">Pound</span> Wright® Brand Naturally Hickory Smoked Maple Flavored Bacon
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                2 <span class="uom">Cups</span> flour
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                3/4 <span class="uom">Cup</span> brown sugar
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 <span class="uom">Tablespoon</span> baking powder
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 <span class="uom">Teaspoon</span> salt
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                5 <span class="uom"></span> eggs
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                2 1/2 <span class="uom">Cup</span> buttermilk
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                2/3 <span class="uom">Cup</span> vegetable oil
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1/2 <span class="uom">Teaspoon</span> vanilla extract
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                chive <span class="uom"></span> for garnish
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 <span class="uom">Teaspoon</span> baking soda
                </div>
                        </div>


                    </div>
                </div>

                <div class="instructions-wrapper recipe-section">
                    <div class="container">


                        <h2>Directions</h2>
                        <div class="instructions">
                            <p>
                                1. Preheat a waffle iron; grease with bacon fat.

                </p>
                            <p>
                                2. Whisk the flour, brown sugar, baking powder, baking soda, and salt together in a large bowl.

                </p>
                            <p>
                                3. In a separate bowl, beat eggs, buttermilk, oil and vanilla together.
                </p>
                            <p>
                                4. Fold the wet mixture into the flour mixture.

                </p>
                            <p>
                                5. Add cooked bacon and stir, be careful not to over mix.

                </p>
                            <p>
                                6. Ladle 1/3 cup of the batter into the preheated waffle iron and cook until desired texture.


                </p>
                        </div>


                        <div></div>
                    </div>
                </div>
            </div>
        </div>


        <article class="locator-callout">
            <div class="quick-form-container">


                <div class="product-locator-search">
                    <h2>Find Our Products</h2>
                    <form data-url="/locator/" action="/locator/" method="get">
                        <input Value="ANY_ANY" id="PROD" name="PROD" type="hidden" value="ANY_ANY" />
                        <div class="input-wrapper">
                            <input data-val="true" data-val-regex="Please enter a 5-digit ZIP code" data-val-regex-pattern="^\d{5}" data-val-required="Please enter a 5-digit ZIP code" id="ZIP" maxLength="5" name="ZIP" placeholder="Enter Zip" type="tel" defaultValue="" />
                            <input type="submit" value="Locate Bacon"></input>
                            <span class="field-validation-valid" data-valmsg-for="ZIP" data-valmsg-replace="true"></span>
                        </div>
                    </form>
                </div>

            </div>
        </article>
    </Layout>
)

export default RecipePage
